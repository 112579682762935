import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import appConfig from "../../config/config.json";
import { AppThunk } from "../reduxStore";
import { findPriceChangeURL, formatDateForAPI } from "../../utils/utils";
import { IServiceURL } from "../../interfaces/serviceURL.interface";
import { IPromotion } from "../../interfaces/promotion.interface";
import { updatePriceChange } from "../../utils/api/pricechange.service";

interface getPromotionsState {
  data: IPromotion[];
  isLoading: boolean;
  error: string | null;
}

const initialState: getPromotionsState = {
  data: [],
  isLoading: false,
  error: null,
};

const PromotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    updatePriceChangeFlag(state, action: PayloadAction<{ id: number; price_change: boolean }>) {
      state.data = state.data.map(priceChange => {
        if (priceChange.id === action.payload.id) {
          return {
            ...priceChange,
            price_change: action.payload.price_change
          }
        } else {
          return priceChange;
        }
      });
    },
    getPromotionsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getPromotionsDetailsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    getPromotionsDetailsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
    updatePromotionsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    updatePromotionsDetailsSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    updatePromotionsDetailsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
    togglePDFSign(state, action: PayloadAction<any>) {
      state.data = state.data.map(promotion => {
        if(promotion.id === action.payload) {
          return {
            ...promotion,
            pdf_flag: !promotion.pdf_flag
          }
        } else {
          return promotion
        }
      });
    },
    toggleAltColorSign(state, action: PayloadAction<any>) {
      state.data = state.data.map(promotion => {
        if(promotion.id === action.payload) {
          return {
            ...promotion,
            alt_color_flag: !promotion.alt_color_flag
          }
        } else {
          return promotion
        }
      });
    },
    updateComments(state, action: PayloadAction<any>) {
      state.data = state.data.map(promotion => {
        if(promotion.id === action.payload.id) {
          return {
            ...promotion,
            comments: action.payload.comments
          }
        } else {
          return promotion
        }
      });
    },

  },
});

export default PromotionsSlice.reducer;

const {
    getPromotionsStart,
    getPromotionsDetailsSuccess,
    getPromotionsDetailsFailed,
    updatePromotionsDetailsSuccess,
    updatePromotionsDetailsFailed,
    togglePDFSign,
    toggleAltColorSign,
    updateComments
} = PromotionsSlice.actions;

const addBaseId=(jsonArray: IPromotion[]): IPromotion[]=> { 
  return jsonArray.map((obj, index) => ({
    ...obj, 
    base_id: index + 1, 
  })); 
} 

export const fetchPromotions = (isChain: boolean, effectiveDate: Date, serviceURLs: IServiceURL[],): AppThunk => async (dispatch) => {
  try {
    dispatch(getPromotionsStart());
    
    if (!serviceURLs.length) return;

    // Fetch promotions from backend
    const priceChangeAPI = findPriceChangeURL(serviceURLs, appConfig.stage); // TODO: to pull env specific when CI is integrated.
    if (!priceChangeAPI) {
      throw new Error("Could not find Price Change API.")
    }

    const options = {
      "headers": {
        "x-api-key": priceChangeAPI?.authorization.apiKey || ''
      }
    };
    
    const query = new URLSearchParams({
      effective_date: formatDateForAPI(effectiveDate),
      chain: isChain ? "true" : "false"
    });

    const response = await fetch(`${priceChangeAPI?.urlPath}/promotions?${query.toString()}`, options);
    const data = await response.json();

    if (data) {
      const newArrayWithBaseId = addBaseId(data);
      dispatch(getPromotionsDetailsSuccess(newArrayWithBaseId));
    }
  } catch (error) {
    console.log("ERROR fetching promotions: ", error);
    dispatch(getPromotionsDetailsFailed(error));
  }
};

export const togglePDFSignAndSync = (
  priceChangeId: Number,
  serviceURLs: IServiceURL[],
  stores: string[],
  isPdf: Boolean,
  isAltColor: Boolean
): AppThunk => async (dispatch) => {
  try {
    // Make API call to update the database
    const priceChangeAPI = findPriceChangeURL(serviceURLs,  appConfig.stage); // TODO: to pull env specific when CI is integrated.
    
    let storeNums = stores[0] ? stores : ["9999"];

    if (!isPdf && isAltColor) { //Call to update alt color to false when pdf is selected
      const body = {
          "is_alt_color": !isAltColor,
          "stores": storeNums
      }
      await updatePriceChange(priceChangeAPI!, body, priceChangeId);
    } else if (isPdf && isAltColor) { //Call to update alt color to true when pdf is de-selected
      const body = {
        "is_alt_color": isAltColor,
        "stores": storeNums
      }
      await updatePriceChange(priceChangeAPI!, body, priceChangeId);
    }

    const body = {
        "is_pdf": !isPdf,
        "stores": storeNums
    }

    const response= await updatePriceChange(priceChangeAPI!, body, priceChangeId) as any;
    const data: any = await response.json();

    // Dispatch an action to update Redux store with the new item
    dispatch(togglePDFSign(priceChangeId))
    dispatch(updatePromotionsDetailsSuccess());
    return data.pricechangedata[0].price_change === true;
  } catch (error) {
    console.error('Error updating PDF sign:', error);
    // Dispatch an action to handle errors if needed
    dispatch(updatePromotionsDetailsFailed(error));
  }
};

export const toggleGenericAltAndSync = (
  priceChangeId: Number,
  serviceURLs: IServiceURL[],
  stores: string[],
  isAltColor: Boolean,
): AppThunk => async (dispatch) => {
  try {
    // Make API call to update the database
    const priceChangeAPI = findPriceChangeURL(serviceURLs,  appConfig.stage); // TODO: to pull env specific when CI is integrated.

    let storeNums = stores[0] ? stores : ["9999"];
    const body = {
        "is_alt_color": !isAltColor,
        "stores": storeNums
    }

    const response= await updatePriceChange(priceChangeAPI!, body, priceChangeId) as any;
    const data: any = await response.json();

    // Dispatch an action to update Redux store with the new item
    dispatch(toggleAltColorSign(priceChangeId))
    dispatch(updatePromotionsDetailsSuccess());
    return data.pricechangedata[0].price_change === true;
  } catch (error) {
    console.error('Error updating Generic Alt sign:', error);
    // Dispatch an action to handle errors if needed
    dispatch(updatePromotionsDetailsFailed(error));
  }
};

export const updateCommentAndSync = (
  priceChangeId: Number,
  serviceURLs: IServiceURL[],
  stores: string[],
  comments: string
): AppThunk => async (dispatch) => {
  try {
    const priceChangeAPI = findPriceChangeURL(serviceURLs,  appConfig.stage); // TODO: to pull env specific when CI is integrated.
    
    let storeNums = stores[0] ? stores : ["9999"];
    const body = {
        "comments": comments,
        "stores": storeNums
    }
    const response= await updatePriceChange(priceChangeAPI!, body, priceChangeId) as any;
    const data: any = await response.json();

    // Dispatch an action to update Redux store with the new item
    dispatch(updateComments({ id: priceChangeId, comments: comments }))
    dispatch(updatePromotionsDetailsSuccess());
    return data.pricechangedata[0].price_change === true;
  } catch (error) {
    console.error('Error updating comments:', error);
    dispatch(updatePromotionsDetailsFailed(error));
  }
}

export const updatePriceChangeFlag = (priceChangeId: Number, priceChange: boolean) => ({
  type: 'priceChanges/updatePriceChangeFlag',
  payload: { id: priceChangeId, price_change: priceChange }
});