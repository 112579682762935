import { IPath } from "../../interfaces/serviceURL.interface";
import { formatDateForAPI } from "../utils"

const getPriceChanges = async (priceChangeAPI: IPath, priceChangesStoreParam: string, effectiveDate: Date) => {
    try {
        const options = {
            "headers": {
                "x-api-key": priceChangeAPI?.authorization.apiKey || ''
            }
        };
        const query = new URLSearchParams({ effective_date: formatDateForAPI(effectiveDate) });
        const response = await fetch(`${priceChangeAPI?.urlPath}/pricechanges/${priceChangesStoreParam}?${query.toString()}`, options)

        if (!response.ok) {
            console.log(response.statusText);
            throw new Error(response.statusText);
        }

        return response;
    } catch (err) {
        console.log(`Fetching Price Changes failed. ERR:: ${err}`);
        return err;
    }
}

const updatePriceChange = async (priceChangeAPI: IPath, body: Object, priceChangeId: Number) => {
    try {
        const options = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "x-api-key": priceChangeAPI?.authorization.apiKey || ''
            },
            "body": JSON.stringify(body)
        };

        const response = await fetch(`${priceChangeAPI?.urlPath}/pricechange/${priceChangeId}`, options);

        if (!response.ok) {
            console.log(response.statusText);
            throw new Error(response.statusText);
        }
        
        return response;
    } catch (err) {
        console.log(`Updating Price Changes failed. ERR:: ${err}`);
        return err;
    }
}

const getEffectiveDates = async (priceChangeAPI: IPath) => {
    try {
        const options = {
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "x-api-key": priceChangeAPI?.authorization.apiKey || ''
            }
        };

        const response = await fetch(`${priceChangeAPI?.urlPath}/batch`, options);

        if (!response.ok) {
            console.log(response.statusText);
            throw new Error(response.statusText);
        }

        return response;
    } catch (err) {
        console.log(`Fetching effective dates failed. ERR:: ${err}`);
        return err;
    }
}

const getSignKitColors = async (priceChangeAPI: IPath) => {
    try {
        const options = {
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "x-api-key": priceChangeAPI?.authorization.apiKey || ''
            }
        };

        const response = await fetch(`${priceChangeAPI?.urlPath}/sign_kit`, options);

        if (!response.ok) {
            console.log(response.statusText);
            throw new Error(response.statusText);
        }

        return response;
    } catch (err) {
        console.log(`Fetching price changes failed. ERR:: ${err}`);
        return err;
    }
}

const updateSignKitColors = async (priceChangeAPI: IPath, body: Object, signKit: String) => {
    try {
        const options = {
            "method": "PUT",
            "headers": {
                "Content-Type": "application/json",
                "x-api-key": priceChangeAPI?.authorization.apiKey || ''
            },
            "body": JSON.stringify(body)
        };

        const response = await fetch(`${priceChangeAPI?.urlPath}/pricechanges/${signKit}`, options);

        if (!response.ok) {
            console.log(response.statusText);
            throw new Error(response.statusText);
        }
    } catch (err) {
        console.log(`Updating Sign Kit color failed. ERR:: ${err}`);
        return err;
    }
}

export {
    getPriceChanges,
    updatePriceChange,
    getEffectiveDates,
    getSignKitColors,
    updateSignKitColors,
}