import { useEffect, useState } from "react";
import { Layout, Alert, Space } from "antd";
import PromotionsTable from "../table/PromotionsTable";
import Spinner from "../loading/Spinner";
import { fetchPromotions, togglePDFSignAndSync, updateCommentAndSync, toggleGenericAltAndSync, updatePriceChangeFlag} from "../../reduxStore/reducers/promotionsSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reduxStore/reducers/rootReducer";
import { fetchServiceURLs } from "../../reduxStore/reducers/serviceURLsSlice";
import { useOktaAuth } from "@okta/okta-react";
import { fetchSignKitColors } from "../../reduxStore/reducers/signKitColorsSlice";
const { Content } = Layout;

const Promotions = ({effectiveDate} : {effectiveDate:Date}) => {
  const { authState }= useOktaAuth();
  const dispatch = useDispatch<any>();
  const promotionsState = useSelector(
    (state: RootState) => state.promotions
  );
  const serviceURLsState = useSelector(
    (state: RootState) => state.serviceURLs
  );

  const [triggerEffect, setTriggerEffect] = useState<{
      priceChangeId: Number,
      isPriceChange: boolean | null
    }>({ priceChangeId: 0, isPriceChange: null });
    
  const pdfSignClickHandler = async (priceChangeId: Number, isPdf: Boolean, stores: string[], isAltColor: Boolean) => {
    const isPriceChange = await dispatch(togglePDFSignAndSync(priceChangeId, serviceURLsState.data, stores, isPdf, isAltColor));
    setTriggerEffect({ priceChangeId, isPriceChange });
  }

  const genericAltSignClickHandler = async (priceChangeId: Number, isAltColor: Boolean, stores: string[]) => {
    const isPriceChange = await dispatch(toggleGenericAltAndSync(priceChangeId, serviceURLsState.data, stores, isAltColor));
    setTriggerEffect({ priceChangeId, isPriceChange });
  }

  const commentsSubmitHandler = async (priceChangeId: Number, comments: string, stores: string[]) => {
    const isPriceChange = await dispatch(updateCommentAndSync(priceChangeId, serviceURLsState.data, stores, comments))
    setTriggerEffect({ priceChangeId, isPriceChange });
  }

  useEffect(() => {
      if (triggerEffect.isPriceChange !== null) {
        dispatch(updatePriceChangeFlag(triggerEffect.priceChangeId, triggerEffect.isPriceChange));
      }
    }, [dispatch, triggerEffect]);

  useEffect(() => {
    if (serviceURLsState.data.length > 0) {
      dispatch(fetchPromotions(false, effectiveDate, serviceURLsState.data)); // NOTE: false (isChain is currently hardcoded)
      dispatch(fetchSignKitColors(serviceURLsState.data));
    }
  }, [fetchPromotions, effectiveDate, serviceURLsState.data]);

  useEffect(() => {
    dispatch(fetchServiceURLs());
  }, [dispatch,authState]);

  const promotionsTableParams = {
    promotionsData: promotionsState.data,
    pdfSignClickHandler,
    commentsSubmitHandler,
    genericAltSignClickHandler
  }

  return (
    <Content>
      <Space direction="vertical" style={{ width: '100%' }}>
        {serviceURLsState.error ? (<Alert message={serviceURLsState.error} type="error" />) : (<></>)}
        {promotionsState.error ? (<Alert message={promotionsState.error} type="error" />) : (<></>)}

      </Space>
      {promotionsState.isLoading || serviceURLsState.isLoading ? (<Spinner />) :
        (<PromotionsTable {...promotionsTableParams} />)}
    </Content>
  );
};

export default Promotions;
