import { useEffect, useState } from "react";
import { Layout, Col, Row, Alert, Space, Spin } from "antd";
import PriceChangeTable from "../table/PriceChangeTable";
import Spinner from "../loading/Spinner";
import { fetchPriceChanges, togglePDFSignAndSync, toggleGenericAltAndSync, updateCommentAndSync,updatePriceChangeFlag } from "../../reduxStore/reducers/priceChangesSlice";
import { fetchServiceURLs } from "../../reduxStore/reducers/serviceURLsSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reduxStore/reducers/rootReducer";
import { fetchSignKitColors } from "../../reduxStore/reducers/signKitColorsSlice";
import { useOktaAuth } from "@okta/okta-react";

const { Content } = Layout;

const HomePage = ({store, effectiveDate} : {store:string, effectiveDate:Date}) => {

  const { authState }= useOktaAuth();
  const dispatch = useDispatch<any>();
  const priceChangesState = useSelector(
    (state: RootState) => state.priceChanges
  );
  const serviceURLsState = useSelector(
    (state: RootState) => state.serviceURLs
  );
  const [triggerEffect, setTriggerEffect] = useState<{
    priceChangeId: Number,
    isPriceChange: boolean | null
  }>({ priceChangeId: 0, isPriceChange: null });

  const pdfSignClickHandler = async (priceChangeId: Number, isPdf: Boolean, stores: string[], isAltColor: Boolean) => {
    const isPriceChange = await dispatch(togglePDFSignAndSync(priceChangeId, serviceURLsState.data, stores, isPdf, isAltColor));
    setTriggerEffect({ priceChangeId, isPriceChange });
  }

  const genericAltSignClickHandler = async (priceChangeId: Number, isAltColor: Boolean, stores: string[]) => {
    const isPriceChange = await dispatch(toggleGenericAltAndSync(priceChangeId, serviceURLsState.data, stores, isAltColor));
    setTriggerEffect({ priceChangeId, isPriceChange });
  }

  const commentsSubmitHandler = async (priceChangeId: Number, comments: string, stores: string[]) => {
    const isPriceChange = await dispatch(updateCommentAndSync(priceChangeId, serviceURLsState.data, stores, comments));
    setTriggerEffect({ priceChangeId, isPriceChange });
  }

  useEffect(() => {
    if (triggerEffect.isPriceChange !== null) {
      dispatch(updatePriceChangeFlag(triggerEffect.priceChangeId, triggerEffect.isPriceChange));
    }
  }, [dispatch, triggerEffect]);


  useEffect(() => {
    dispatch(fetchServiceURLs());
  }, [authState, dispatch]);

  useEffect(() => {
    console.log("Store: ", store);
    if (store === "" || (store !== "" && store.length === 4) && serviceURLsState.data.length > 0) {
      dispatch(fetchPriceChanges(store, serviceURLsState.data, effectiveDate));
      dispatch(fetchSignKitColors(serviceURLsState.data));
    }
  }, [fetchPriceChanges, fetchSignKitColors, store, effectiveDate]);

  const priceChangeTableParams = {
    priceChangeData: priceChangesState.data,
    pdfSignClickHandler,
    genericAltSignClickHandler,
    commentsSubmitHandler,
    store
  }

  return (
    <Content>
      <Space direction="vertical" style={{ width: '100%' }}>
        {serviceURLsState.error ? (<Alert message={serviceURLsState.error} type="error" />) : (<></>)}
        {priceChangesState.error ? (<Alert message={priceChangesState.error} type="error" />) : (<></>)}
      </Space>
      { (priceChangesState.isLoading || serviceURLsState.isLoading) ? (<Spinner />) :
        (<PriceChangeTable {...priceChangeTableParams} />)}
    </Content>
  );
};

export default HomePage;
